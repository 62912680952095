/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "roboto-fontface/css/roboto/sass/roboto-fontface";
@import "material-symbols";
@import './assets/styles/variables';

html,
body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  background-color: $pozi_white;
  overflow: hidden;
  color: $pozi_black;
  font-size: 16px;
}

i.material-symbols-outlined {
  font-size: $icon-size;

  &.filled {
    font-variation-settings: 'FILL' 1;
  }

  &.green {
    color: $pozi_green;
  }

  &.orange {
    color: $pozi_orange;
  }
}

div.dropdown-menu {
  border-radius: $border-radius;
  border: none;
  box-shadow: $black_light_box_shadow;
  padding: $spacing-m;
}

ul.dropdown-menu {
  border-radius: $border-radius;
  border: none;
  box-shadow: $black_light_box_shadow;

  hr {
    margin: 0.25rem 1rem;
    border-color: $pozi_mid_gray;
    opacity: 1;
  }

  .dropdown-item {
    cursor: pointer;
    display: flex;
    gap: calc($gap * 2);

    &.active,
    &:hover {
      color: $pozi_black;
      background-color: $pozi_light_gray;
    }

    &.orange {
      color: $pozi_orange;
    }
  }
}

a,
a:hover {
  color: $pozi_black;
}

.btn,
.btn:active {
  align-items: center;
  border-radius: $border-radius;
  display: inline-flex;
  flex-shrink: 0;
  gap: $gap;
  padding: $spacing-s;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
  border: none;
  white-space: nowrap;
  transition: opacity .2s;

  &.blue {
    background-color: $pozi_blue;
    color: $pozi_white;
  }

  &.green {
    background-color: $pozi_green;
    color: $pozi_white;
  }

  &.gray-outlined {
    border: 1px solid $pozi_mid_gray;
  }

  &.gray {
    background-color: $pozi_light_gray;
  }

  &.orange {
    background-color: $pozi_orange;
    color: $pozi_white;
  }

  .material-symbols-outlined {
    vertical-align: middle;
  }

  &[disabled] {
    opacity: .6;
    pointer-events: none;
  }
}

.nav-tabs {
  border: none;
  background-color: $pozi_white;
  margin: 0 (-$container-padding) (-$container-padding);

  .nav-item {
    height: $nav-item-height;

    .nav-link {
      color: $pozi_black;
      border: none;

      &.active {
        border: none;
        background-color: transparent;
        font-weight: bold;
      }

      &.active:after {
        content: "";
        display: block;
        width: 100%;
        height: $spacing-xs;
        background-color: $pozi_blue;
        border-top-right-radius: $spacing-xs;
        border-top-left-radius: $spacing-xs;
        margin-top: 4px;
      }
    }
  }
}

.container {
  padding: $container-padding 40px 0;
  height: 100%;
  max-width: unset;

  .title {
    display: flex;
    gap: $gap;
    align-items: center;
    white-space: nowrap;

    i {
      font-size: 20px;
    }
  }

  table {
    margin: 0;

    &:not(:first-of-type) {
      margin-top: $spacing-m;
    }
  }

  thead th {
    background-color: $pozi_white;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .table-sm> :not(caption)>*>* {
    padding: $spacing-s;
  }

  .text-end {
    padding: 0 $spacing-s !important;
  }

  table,
  table>thead>tr>th,
  table>tbody>tr>td {
    border-bottom: 1px solid $pozi_mid_gray;
  }
}