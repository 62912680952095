// COLORS
$pozi_white: #FEFEFE;
$pozi_black: #14181C;
$pozi_light_gray: #E8EEF4;
$pozi_mid_gray: #b4c8dc;
$pozi_dark_gray: #2C343C;

$pozi_blue: #28C8F0;
$pozi_green: #A0DC00;
$pozi_yellow: #F0C800;
$pozi_orange: #FA7800;

$black_light_box_shadow: 0px 4px 16px 0px #2C343C29;

// SPACINGS
$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-m: 16px;

// GLOBAL VARIABLES
$container-padding: $spacing-m;
$container-row-height: 40px;
$nav-item-height: 40px;
$sidebar-width: 225px;
$icon-size: 24px;
$gap: 6px;
$border-radius: 12px;